var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: `${_vm.target.checkDate} 일상점검결과`,
            columns: _vm.grid.columns,
            merge: _vm.grid.merge,
            gridHeight: _vm.grid.height,
            data: _vm.filteringData,
            editable: _vm.editable && !_vm.disabled,
            filtering: false,
            columnSetting: false,
            usePaging: false,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "customCol"
                    ? _c(_vm.imprComponent, {
                        tag: "component",
                        attrs: {
                          col: col,
                          props: props,
                          inputEditable: _vm.editable && !_vm.disabled,
                          isImmShow: true,
                          requestContentsCols: _vm.requestContentsCols,
                          tableKey: "sopPreWorkCheckTargetResultId",
                          ibmTaskTypeCd: "ITT0000195",
                          ibmTaskUnderTypeCd: "ITTU000221",
                        },
                        on: { imprChange: _vm.imprChange },
                      })
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "customFilter" },
            [
              _c("c-radio", {
                attrs: {
                  comboItems: _vm.filterItems,
                  label: "",
                  name: "filter",
                },
                model: {
                  value: _vm.filter,
                  callback: function ($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter",
                },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "table-header-append" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "padding-top": "7px",
                    "font-size": "1.1em",
                    "font-weight": "600",
                  },
                },
                [_vm._v("점검자 ")]
              ),
              _c("c-field", {
                staticClass: "preWorkDaliyCheckUser",
                attrs: {
                  disabled: _vm.disabled,
                  editable: _vm.editable,
                  name: "userId",
                  placeholder: _vm.$label("LBL0002218"),
                },
                model: {
                  value: _vm.target.userId,
                  callback: function ($$v) {
                    _vm.$set(_vm.target, "userId", $$v)
                  },
                  expression: "target.userId",
                },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.target,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveInfo,
                          btnCallback: _vm.saveInfoCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-upload", {
        staticStyle: { "margin-top": "8px !important" },
        attrs: {
          attachInfo: _vm.attachInfo,
          editable: _vm.editable && !_vm.disabled,
          label: "첨부파일",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }